/*Одна строка о пользователе*/
.list-group-item{
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
}

/* Вложеные div в строке*/
.list-group-item div:nth-child(1){
    padding-left: 20px;
    font-size: 14pt;
}
.list-group-item div:nth-child(2){
    text-align: right;
    color: grey;
    font-size: 10pt;
}
.list-group-item div:nth-child(3){
    text-align: center;    
}